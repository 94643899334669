<script>
import { mapState } from 'vuex'
import VideoDetail from '@/views/fun_template/resource/resource_center/detail_type/video'
import MicrosoftFileDetail from '@/views/fun_template/resource/resource_center/detail_type/microsoft_file'
import LinkDetail from '@/views/fun_template/resource/resource_center/detail_type/link'
import oss from '@/api/modules/oss'
export default {
  components: {
    VideoDetail,
    MicrosoftFileDetail,
    LinkDetail,
  },
  computed: {
    ...mapState({
      // resourceFileList: state => state.resource.fileList,
      resourceFileData: state => state.resource.fileData,
    }),
    showVideo () {
      const {
        resourceFileData
      } = this
      if (!resourceFileData) {
        return false
      }
      const {
        fileType
      } = resourceFileData
      return fileType === 'video'
    },
    showMicroSoftFile () {
      const {
        resourceFileData
      } = this
      if (!resourceFileData) {
        return false
      }
      const {
        fileType
      } = resourceFileData
      return fileType === 'word' || fileType === 'excel' || fileType === 'ppt' || fileType === 'pdf'
    },
    showLink () {
      const {
        resourceFileData
      } = this
      if (!resourceFileData) {
        return false
      }
      const {
        fileType
      } = resourceFileData
      return fileType === 'link'
    },
    showDownload () {
      const {
        resourceFileData
      } = this
      if (resourceFileData.type === 3) {
        return false
      }
      return true
    }
  },
  methods: {
    download () {
      const {
        resourceFileData
      } = this
      const {
        file
      } = resourceFileData
      oss.downloadFile({ file })
    },
    async doLike () {
      await this.resourceFileData.doLike()
    },
  }
}
</script>

<template>
<div
  v-if="resourceFileData"
  class="v_resource_resource_center_detail bbox_wrapper"
>
  <div
    class="detail_wrapper d-flex align-stretch justify-space-between"
  >
    <div
      class="detail_group"
    >
      <div
        class="title"
      >{{ resourceFileData.fileName }}</div>
      <div
        class="detail_content_wrapper d-flex justify-center"
      >
        <VideoDetail
          v-if="showVideo"
        ></VideoDetail>
        <MicrosoftFileDetail
          v-if="showMicroSoftFile"
        ></MicrosoftFileDetail>
        <LinkDetail
          v-if="showLink"
        ></LinkDetail>
      </div>
      <div
        class="fun_wrapper d-flex align-center justify-space-between"
      >
        <div
          class="number_wrapper d-flex align-center"
        >
          <div
            class="number_group d-flex align-center"
          >
            <div
              class="icon view"
            ></div>
            <div
              class="number"
            >{{ resourceFileData.browseCount }}</div>
          </div>
          <div
            class="number_group d-flex align-center"
          >
            <div
              class="icon call"
            ></div>
            <div
              class="number"
            >10</div>
          </div>
          <div
            class="number_group d-flex align-center"
          >
            <div
              class="icon like"
            ></div>
            <div
              class="number"
            >{{ resourceFileData.likeCount }}</div>
          </div>
          <div
            class="number_group d-flex align-center"
          >
            <div
              class="icon collect"
            ></div>
            <div
              class="number"
            >{{ resourceFileData.collectCount }}</div>
          </div>
        </div>
        <div
          class="fun_group d-flex align-center"
        >
          <div
            class="btn cp like"
            @click="doLike"
          ></div>
          <div
            v-if="0"
            class="btn cp collect"
          ></div>
          <div
            v-if="showDownload"
            class="btn cp download"
            @click="download"
          ></div>
        </div>
      </div>
    </div>
    <div
      class="detail_info_group d-flex align-start justify-space-between flex-shrink-0"
    >
      <div
        class="info_group"
      >
        <div
          class="title"
        >
          资源信息
        </div>
        <div
          class="line_group d-flex align-start"
        >
          <div
            class="key flex-shrink-0"
          >资源作者:</div>
          <div
            class="value"
          >{{ resourceFileData.userName }}</div>
        </div>
        <div
          class="line_group d-flex align-start"
          v-if="resourceFileData.file"
        >
          <div
            class="key flex-shrink-0"
          >资源大小:</div>
          <div
            class="value"
          >{{ resourceFileData.file.formatSize }}</div>
        </div>
        <div
          v-if="0"
          class="line_group d-flex align-start"
        >
          <div
            class="key flex-shrink-0"
          >所属专业:</div>
          <div
            class="value"
          >会展策划与管理</div>
        </div>
        <div
          class="line_group d-flex align-start"
        >
          <div
            class="key flex-shrink-0"
          >单位:</div>
          <div
            class="value"
          >{{ resourceFileData.ownedEnterprises }}</div>
        </div>
        <div
          class="line_group d-flex align-start"
        >
          <div
            class="key flex-shrink-0"
          >媒体类型:</div>
          <div
            class="value"
          >{{ resourceFileData.typeText }}</div>
        </div>
        <div
          v-if="0"
          class="line_group d-flex align-start"
        >
          <div
            class="key flex-shrink-0"
          >应用类型:</div>
          <div
            class="value"
          >标准</div>
        </div>
        <div
          v-if="0"
          class="line_group d-flex align-start"
        >
          <div
            class="key flex-shrink-0"
          >课程:</div>
          <div
            class="value"
          >会展视觉设计</div>
        </div>
        <div
          class="line_group d-flex align-start"
        >
          <div
            class="key flex-shrink-0"
          >创建时间:</div>
          <div
            class="value"
          >{{ $moment(resourceFileData.createTime).format('YYYY-MM-DD') }}</div>
        </div>
      </div>
      <div
        class="fun_group"
      ></div>
    </div>
  </div>
  <div
    class="relation_wrapper"
  >
    <div
      class="title"
    ></div>
    <div
      class="list_wrapper"
    >
      <template
        v-for="(item, index) in 10"
      >
        <div
          :key="index"
          class="course_wrapper"
        ></div>
      </template>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.v_resource_resource_center_detail {
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  .detail_wrapper {
    padding: 20px 30px;
    .detail_group {
      width: 100%;
      padding: 30px;
      background-color: #fff;
      .title {
        font-weight: bold;
        font-size: 24px;
        color: #333333;
        line-height: 31px;
        text-align: center;
        font-style: normal;
        padding-bottom: 20px;
      }
      .detail_content_wrapper {
        width: 100%;
        padding: 20px;
      }
      .fun_wrapper {
        width: 100%;
        padding-top: 20px;
        .number_wrapper {
          .number_group {
            padding-right: 20px;
            .icon {
              @include background(18px 18px);
              width: 24px;
              height: 24px;
              margin-right: 3px;
              &.view {
                @include background(23px, 18px);
                background-image: url(~@/assets/images/resource/detail_view.png);
              }
              &.call {
                background-image: url(~@/assets/images/resource/detail_call.png);
              }
              &.like {
                background-image: url(~@/assets/images/resource/detail_like.png);
              }
              &.collect {
                background-image: url(~@/assets/images/resource/detail_collect.png);
              }
            }
            .number {
              font-size: 16px;
              color: #999999;
              line-height: 21px;
              text-align: left;
              font-style: normal;
            }
          }
        }
        .fun_group {
          .btn {
            @include transition;
            width: 36px;
            height: 36px;
            margin-left: 24px;
            cursor: pointer;
            &.like {
              background-image: url(~@/assets/images/course_package/like_btn.png);
            }
            &.collect {
              background-image: url(~@/assets/images/course_package/collect_btn.png);
            }
            &.download {
              background-image: url(~@/assets/images/course_package/download_btn.png);
              &:hover {
                background-image: url(~@/assets/images/course_package/download_btn_on.png);
              }
            }
          }
        }
      }
    }
    .detail_info_group {
      background-color: #fff;
      width: 400px;
      border-radius: 4px;
      padding: 30px;
      margin-left: 20px;
      .info_group {


        .title {
          font-weight: bold;
          font-size: 16px;
          color: #333333;
          line-height: 21px;
          text-align: left;
          font-style: normal;
          padding-bottom: 30px;
        }
        .line_group {
          padding-bottom: 10px;
          .key {
            width: 60px;
            font-size: 14px;
            color: #666666;
            line-height: 19px;
            text-align: justify;
            text-align-last: justify;
            font-style: normal;
            margin-right: 10px;
          }
          .value {
            font-size: 14px;
            color: #333333;
            line-height: 19px;
            text-align: left;
            font-style: normal;
          }
        }

      }
      .fun_group {

      }
    }
  }
}
</style>
