var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "v_resource_center_detail_type_video d-flex justify-center",
    },
    [
      _c(
        "div",
        { staticClass: "video d-flex justify-center" },
        [
          _c("Xgplayer", {
            attrs: { config: _vm.config },
            on: {
              player: function ($event) {
                _vm.Player = $event
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }