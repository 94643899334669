var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading,
          expression: "pageLoading",
        },
      ],
      staticClass:
        "v_fun_template_resource_index d-flex flex-column bbox_wrapper",
    },
    [
      !_vm.searchMode
        ? [
            _c("ResourceTitleGroup", {
              staticClass: "flex-shrink-0",
              on: {
                changePageLoading: _vm.changePageLoading,
                changeSearchMode: _vm.changeSearchMode,
              },
            }),
            _c("ResourcePageType", {
              staticClass: "flex-shrink-0",
              attrs: { "page-type": _vm.pageType },
              on: { changePageType: _vm.changePageType },
            }),
            _vm.pageType === 0 && !_vm.coursePackage
              ? _c("ResourceFilter", {
                  staticClass: "flex-shrink-0",
                  on: { changePageLoading: _vm.changePageLoading },
                })
              : _vm._e(),
            _vm.pageType === 1 && !_vm.resourceFileData
              ? _c("ResourceCenterFilterGroup", {
                  on: { changePageLoading: _vm.changePageLoading },
                })
              : _vm._e(),
            _c(
              "div",
              { staticClass: "main_wrapper d-flex flex-column" },
              [
                _vm.showCourseCenter
                  ? _c("CourseCenter", { on: { changePage: _vm.changePage } })
                  : _vm._e(),
                _vm.showResourceCenter
                  ? _c("ResourceCenterPage", {
                      on: { changePage: _vm.changePage },
                    })
                  : _vm._e(),
                _vm.showShoppingMail ? _c("RouterView") : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "page_wrapper flex-shrink-0 d-flex justify-center",
              },
              [
                _vm.page && !_vm.resourceFileData
                  ? _c("el-pagination", {
                      attrs: {
                        "hide-on-single-page": true,
                        background: "",
                        layout: "prev, pager, next",
                        total: _vm.page.totalPages,
                        "current-page": _vm.page.pageNo,
                      },
                      on: { "current-change": _vm.currentPageChange },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.searchMode
        ? [
            _c("ResourceSearch", {
              on: { changeSearchMode: _vm.changeSearchMode },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }