<script>
import { mapState } from 'vuex'
import Xgplayer from 'xgplayer-vue';

export default {
  components:{
    Xgplayer,
  },
  computed: {
    ...mapState({
      // resourceFileList: state => state.resource.fileList,
      resourceFileData: state => state.resource.fileData,
    }),
    src () {
      if (!this.resourceFileData) {
        return ''
      }
      const {
        resourceFileData
      } = this
      const {
        file,
        type,
      } = resourceFileData
      const {
        url
      } = file
      return `${ this.$file_url }${ url }`
    },
  },
  data () {
    return {
      config: {
        id: 'vs',
        url: '/demo.mp4',
        // fitVideoSize: 'fixWidth',
        fitVideoSize: 'auto',
        // width: 1000,
        // height: 337.5
      },
      Player: null,
      ready: false,
      // src: '',
    }
  },
  watch:{
    Player: function(val){
      // console.log(val);
      if(val){
        val.once('ready', ()=>{
          this.ready = true;

          setTimeout(()=>{

            this.Player.src = this.src;
            this.Player.reload();
            // this.Player.start();
            this.Player.start(this.src);
            // console.log('play1')
            this.Player.play();
          },200)
        })
      }
    },
  },
  mounted () {
    let player = new Xgplayer({/*配置*/});
    // console.log(player);
    player.once('ready',()=>{
      // console.log('ready')
    })
  }
}
</script>

<template>
<div
  class="v_resource_center_detail_type_video d-flex justify-center"
>
  <div
    class="video d-flex justify-center"
  >
    <Xgplayer :config="config" @player="Player = $event" />
  </div>
</div>
</template>

<style scoped lang="scss">
.v_resource_center_detail_type_video {
  width: 100%;
  .video {
    width: 100%;
    max-width: 1000px;
  }
}
</style>
