<script>
import global from '@/api/global'
import { mapState } from 'vuex'
import store from '@/store'
export default {
  data () {
    return {
      typeList: [
        { name: '标准课', value: 1 },
        { name: '实践课', value: 2 },
        { name: '专业群课', value: 3 },
        { name: '仿真', value: 4 },
        { name: '思政', value: 5 },
        { name: '双创融合', value: 6 },
        { name: '培训课', value: 7 },
        { name: '其他', value: 8 },
      ],
      type: '',
      enterprise: '',
    }
  },
  computed: {
    ...mapState({
      enterpriseList: state => state.resource.enterpriseList,
    }),
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      // await this.getCoursePackage()
      // this.getList()
      this.getEnterpriseList()
    },
    async getEnterpriseList () {
      await store.dispatch('getResourceEnterpriseList')
      // const res = await global.doPost({
      //   url: '/resource/ownedEnterprisesList',
      // })
      // store.commit(
      //   'setResourceEnterpriseList',
      //   res.contents.page.result
      // )
    },
    async changeEnterprise (item) {
      console.log(item)
      this.enterprise = item
      this.$emit('changePageLoading', true)
      store.commit('setCoursePackageData', undefined)
      await store.dispatch(
        'getCoursePackageList',
        {
          ownedEnterprises: item,
          type: this.type,
        }
      )
      this.$emit('changePageLoading', false)
    },
    async changeType (type) {
      this.type = type
      this.$emit('changePageLoading', true)
      store.commit('setCoursePackageData', undefined)
      await store.dispatch(
        'getCoursePackageList',
        {
          ownedEnterprises: this.enterprise,
          type: this.type
        }
      )
      this.$emit('changePageLoading', false)
    }
  }
}

</script>

<template>
<div
  class="v_fun_template_resource_filter_group bbox_wrapper"
>
  <div
    class="filter_wrapper"
  >
    <div
      class="line_group d-flex align-stretch"
    >
      <div
        class="key_group flex-shrink-0"
      >
        <div
          class="key"
        >
          学&nbsp;校:
        </div>
      </div>
      <div
        class="value_group d-flex align-start"
      >
        <div
          class="list_group d-flex align-start flex-wrap"
        >
          <div
            class="tag cp"
            :class="{
              on: enterprise === ''
            }"
            @click="changeEnterprise('')"
          >
            全部
          </div>
          <template
            v-for="(item, index) in enterpriseList"
          >
            <div
              :key="index"
              class="tag cp"
              :class="{
                on: enterprise === item,
              }"
              @click="changeEnterprise(item)"
            >
              {{ item }}
            </div>
          </template>
        </div>
        <div
          v-if="0"
          class="more flex-shrink-0"
        >
          更多
        </div>
      </div>
    </div>
    <div
      class="line_group d-flex align-stretch"
    >
      <div
        class="key_group flex-shrink-0"
      >
        <div
          class="key"
        >
          类&nbsp;型:
        </div>
      </div>
      <div
        class="value_group d-flex align-start"
      >
        <div
          class="list_group d-flex align-start flex-wrap"
        >
          <div
            class="tag cp"
            :class="{
              on: type === ''
            }"
            @click="changeType('')"
          >
            全部
          </div>
          <template
            v-for="(item, index) in typeList"
          >
            <div
              :key="index"
              class="tag cp"
              :class="{
                on: type === item.value
              }"
              @click="changeType(item.value)"
            >
              {{ item.name }}
            </div>
          </template>
        </div>
        <div
          v-if="0"
          class="more flex-shrink-0"
        >
          更多
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.v_fun_template_resource_filter_group {
  width: 100%;
  background-color: #fff;
  padding: 10px 40px;
  overflow: auto;
  .filter_wrapper {
    border-top: 1px solid #eee;
    width: 100%;
    border-bottom: 1px solid #eee;
    .line_group {
      width: 100%;
      border: {
        top: 1px solid #eee;
        left: 1px solid #eee;
        right: 1px solid #eee;
      }
      .key_group {
        background: #F6F6F6;
        padding: 5px 17px;
        .key {
          width: 71px;
          font-size: 14px;
          color: #333333;
          line-height: 28px;
          text-align: justify;
          font-style: normal;
          text-align-last: justify;
        }
      }
      .value_group {
        padding: 5px 20px;
        .list_group {
          .tag {
            flex-shrink: 0;
            font-size: 14px;
            color: #333333;
            line-height: 28px;
            text-align: left;
            font-style: normal;
            opacity: 0.7;
            padding-right: 30px;
            &.on {
              color: $main_blue;
            }
          }
        }

        .more {
          flex-shrink: 0;
          font-size: 14px;
          color: #333333;
          line-height: 28px;
          text-align: left;
          font-style: normal;
          opacity: 0.7;
        }
      }
    }
  }
}
</style>
