var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.resourceFileData
    ? _c(
        "div",
        { staticClass: "v_resource_resource_center_detail bbox_wrapper" },
        [
          _c(
            "div",
            {
              staticClass:
                "detail_wrapper d-flex align-stretch justify-space-between",
            },
            [
              _c("div", { staticClass: "detail_group" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.resourceFileData.fileName)),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "detail_content_wrapper d-flex justify-center",
                  },
                  [
                    _vm.showVideo ? _c("VideoDetail") : _vm._e(),
                    _vm.showMicroSoftFile
                      ? _c("MicrosoftFileDetail")
                      : _vm._e(),
                    _vm.showLink ? _c("LinkDetail") : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "fun_wrapper d-flex align-center justify-space-between",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "number_wrapper d-flex align-center" },
                      [
                        _c(
                          "div",
                          { staticClass: "number_group d-flex align-center" },
                          [
                            _c("div", { staticClass: "icon view" }),
                            _c("div", { staticClass: "number" }, [
                              _vm._v(_vm._s(_vm.resourceFileData.browseCount)),
                            ]),
                          ]
                        ),
                        _vm._m(0),
                        _c(
                          "div",
                          { staticClass: "number_group d-flex align-center" },
                          [
                            _c("div", { staticClass: "icon like" }),
                            _c("div", { staticClass: "number" }, [
                              _vm._v(_vm._s(_vm.resourceFileData.likeCount)),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "number_group d-flex align-center" },
                          [
                            _c("div", { staticClass: "icon collect" }),
                            _c("div", { staticClass: "number" }, [
                              _vm._v(_vm._s(_vm.resourceFileData.collectCount)),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "fun_group d-flex align-center" },
                      [
                        _c("div", {
                          staticClass: "btn cp like",
                          on: { click: _vm.doLike },
                        }),
                        0
                          ? _c("div", { staticClass: "btn cp collect" })
                          : _vm._e(),
                        _vm.showDownload
                          ? _c("div", {
                              staticClass: "btn cp download",
                              on: { click: _vm.download },
                            })
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "detail_info_group d-flex align-start justify-space-between flex-shrink-0",
                },
                [
                  _c("div", { staticClass: "info_group" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("\n          资源信息\n        "),
                    ]),
                    _c(
                      "div",
                      { staticClass: "line_group d-flex align-start" },
                      [
                        _c("div", { staticClass: "key flex-shrink-0" }, [
                          _vm._v("资源作者:"),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(_vm._s(_vm.resourceFileData.userName)),
                        ]),
                      ]
                    ),
                    _vm.resourceFileData.file
                      ? _c(
                          "div",
                          { staticClass: "line_group d-flex align-start" },
                          [
                            _c("div", { staticClass: "key flex-shrink-0" }, [
                              _vm._v("资源大小:"),
                            ]),
                            _c("div", { staticClass: "value" }, [
                              _vm._v(
                                _vm._s(_vm.resourceFileData.file.formatSize)
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    0
                      ? _c(
                          "div",
                          { staticClass: "line_group d-flex align-start" },
                          [
                            _c("div", { staticClass: "key flex-shrink-0" }, [
                              _vm._v("所属专业:"),
                            ]),
                            _c("div", { staticClass: "value" }, [
                              _vm._v("会展策划与管理"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "line_group d-flex align-start" },
                      [
                        _c("div", { staticClass: "key flex-shrink-0" }, [
                          _vm._v("单位:"),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(_vm._s(_vm.resourceFileData.ownedEnterprises)),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "line_group d-flex align-start" },
                      [
                        _c("div", { staticClass: "key flex-shrink-0" }, [
                          _vm._v("媒体类型:"),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(_vm._s(_vm.resourceFileData.typeText)),
                        ]),
                      ]
                    ),
                    0
                      ? _c(
                          "div",
                          { staticClass: "line_group d-flex align-start" },
                          [
                            _c("div", { staticClass: "key flex-shrink-0" }, [
                              _vm._v("应用类型:"),
                            ]),
                            _c("div", { staticClass: "value" }, [
                              _vm._v("标准"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    0
                      ? _c(
                          "div",
                          { staticClass: "line_group d-flex align-start" },
                          [
                            _c("div", { staticClass: "key flex-shrink-0" }, [
                              _vm._v("课程:"),
                            ]),
                            _c("div", { staticClass: "value" }, [
                              _vm._v("会展视觉设计"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "line_group d-flex align-start" },
                      [
                        _c("div", { staticClass: "key flex-shrink-0" }, [
                          _vm._v("创建时间:"),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(
                            _vm._s(
                              _vm
                                .$moment(_vm.resourceFileData.createTime)
                                .format("YYYY-MM-DD")
                            )
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "fun_group" }),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "relation_wrapper" }, [
            _c("div", { staticClass: "title" }),
            _c(
              "div",
              { staticClass: "list_wrapper" },
              [
                _vm._l(10, function (item, index) {
                  return [
                    _c("div", { key: index, staticClass: "course_wrapper" }),
                  ]
                }),
              ],
              2
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "number_group d-flex align-center" }, [
      _c("div", { staticClass: "icon call" }),
      _c("div", { staticClass: "number" }, [_vm._v("10")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }