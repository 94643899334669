<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      // resourceFileList: state => state.resource.fileList,
      resourceFileData: state => state.resource.fileData,
    }),
    src () {
      if (!this.resourceFileData) {
        return ''
      }
      const {
        resourceFileData
      } = this
      const {
        file,
      } = resourceFileData
      const {
        url
      } = file
      return `https://view.officeapps.live.com/op/view.aspx?src=${ this.$file_url }${ url }`
    }
  }
}
</script>

<template>
<div
  class="v_resource_center_detail_type_microsoft_file d-flex justify-center"
>
  <div
    class="microsoft_file d-flex justify-center"
  >
    <iframe
      :src="src"
      width="100%"
      height="600px"
      frameborder="0"
    ></iframe>
  </div>
</div>
</template>

<style scoped lang="scss">
.v_resource_center_detail_type_microsoft_file {
  width: 100%;
  .microsoft_file {
    width: 100%;
    max-width: 1000px;
  }
}
</style>
