<script>
export default {
  methods: {
    goToSearch () {
      // this.$router.push({
      //   name: 'fun_template_resource_search'
      // })
      this.$emit('changeSearchMode', true)
    }
  }
}
</script>

<template>
<div
  class="v_fun_template_resource_title_group d-flex align-center justify-space-between un_sel bbox_wrapper"
>
  <div
    class="name"
  >
    会展与商务活动专业群实践教学资源库
  </div>
  <div
    class="fun_group d-flex align-center"
  >
    <div
      class="search icon cp"
      @click="goToSearch"
    ></div>
    <div
      class="user icon"
    ></div>
  </div>
</div>
</template>

<style scoped lang="scss">
.v_fun_template_resource_title_group {
  width: 100%;
  padding: 20px 40px;
  background-color: #fff;
  .name {
    font-weight: bold;
    font-size: 24px;
    color: #333333;
    line-height: 31px;
    text-align: left;
    font-style: normal;
  }
  .fun_group {
    .icon {
      @include background(20px 20px);
      @include transition;
      margin-left: 10px;
      width: 40px;
      height: 40px;
      cursor: pointer;
      &:hover {
        background-color: #F0F0F0;
        border-radius: 4px;
      }
      &.search {
        background-image: url(~@/assets/images/resource/title_bar_search.png);
      }
      &.user {
        background-image: url(~@/assets/images/resource/title_bar_user.png);
      }

    }
  }
}

</style>
