<script>
export default {
  data () {
    return {
      typeList: [
        { name: '课程中心', value: 0 },
        { name: '资源中心', value: 1 },
        { name: '资源库门户', value: 2 },
        { name: '模板商城', value: 3 },
      ]
    }
  },
  props: {
    pageType: {
      type: Number,
      default: 0
    },
  },
  methods: {
    changePageType (type) {
      if (this.pageType === type) {
        return
      }
      this.$emit(
        'changePageType',
        type
      )
    }
  }
}
</script>

<template>
<div
  class="v_fun_template_resource_page_type bbox_wrapper"
>
  <div
    class="page_type_wrapper d-flex align-center"
  >
    <template
      v-for="(item, index) in typeList"
    >
      <div
        :key="index"
        class="page_type"
        :class="{
          on: item.value === pageType
        }"
        @click="changePageType(item.value)"
      >
        {{ item.name }}
      </div>
    </template>
  </div>
</div>
</template>

<style scoped lang="scss">
.v_fun_template_resource_page_type {
  width: 100%;
  background-color: #fff;
  padding: 0 40px;
  border-bottom: 2px solid #f0f0f0;
  .page_type_wrapper {
    .page_type {
      @include transition;
      font-weight: bold;
      font-size: 16px;
      color: #333333;
      line-height: 44px;
      text-align: left;
      font-style: normal;
      opacity: 0.5;
      margin-right: 40px;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      &:hover {
        opacity: 1;
        //border-bottom-color: $main_blue;
      }
      &.on {
        opacity: 1;
        border-bottom-color: $main_blue;
      }

    }
  }
}
</style>
