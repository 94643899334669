<script>
import TaskModule from '@/views/ShoppingMail/Modules/Task/Unit/Major.vue';
import store from '@/store'
import { mapState } from 'vuex'
import TaskDetail from '@/views/fun_template/resource/common/task_detail'
import CheckCrousePackage from '@/components/resource_library/course_package/check/index'
import ResourceCenterDetail from '@/views/fun_template/resource/resource_center/detail'
import request from '@/api/request'
import CoursePackage from '@/models/cource_package'
import TeachingPackageDetailPage from '@/views/ShoppingMail/TeachingPackage/Details.vue';




export default {
  components: {
    TaskModule,
    TaskDetail,
    CheckCrousePackage,
    ResourceCenterDetail,
    TeachingPackageDetailPage,
  },
  data () {
    return {
      keyword: '',
      searchType: 1,
      loading: false,
      page: undefined,
      morePackage: false,
      moreTask: false,
      currentTaskDetail: undefined,
      sellPackage: undefined,
      sellTask: undefined,
    }
  },
  computed: {
    ...mapState({
      // coursePackage: state => state.course_package.data,
      coursePackageList: state => state.course_package.packageList,
      shoppingPackageList: state => state.resource.shoppingPackageList,
      shoppingTaskList: state => state.resource.shoppingTaskList,
      resourceFileList: state => state.resource.fileList,
      coursePackage: state => state.course_package.data,
      resourceFileData: state => state.resource.fileData,
    }),
    showDetail () {
      const {
        coursePackage,
        resourceFileData,
        sellPackage,
        sellTask
      } = this
      return coursePackage || resourceFileData || sellPackage || sellTask
    },
    detailName () {
      const {
        coursePackage,
        resourceFileData,
        sellPackage,
      } = this
      if (coursePackage) {
        return coursePackage.title
      }
      if (resourceFileData) {
        return resourceFileData.fileName
      }
      if (sellPackage) {
        return sellPackage.title
      }
      return ''
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      store.commit('setResourceFileData', undefined)
      store.commit('setCoursePackageData', undefined)
      await this.doSearch()
    },
    inputKeyDown (e) {
      console.log(e)
      if (e.keyCode === 13) {
        this.doSearch()
      }
    },
    async doSearch (params) {
      if (this.loading) {
        return
      }
      this.loading = true
      const {
        searchType,
        keyword,
      } = this
      if (searchType === 1) {
        const res = await store.dispatch(
          'getCoursePackageList',
          {
            keyword,
            pn: params?.pn || ''
          }
        )
        if (res.message === 'success') {
          this.$set(
            this,
            'page',
            res.contents.page
          )
        }
      }
      if (searchType === 2) {
        const res = await store.dispatch(
          'getResourceFileList',
          {
            keyword,
            pn: params?.pn || ''
          }
        )

        if (res.message === 'success') {
          this.$set(
            this,
            'page',
            res.contents.page
          )
        }
        console.log(this.page)
      }
      if (searchType === 3) {
        const pRes = await store.dispatch(
          'getResourceShoppingPackageList',
          {
            keyword
          }
        )
        if (pRes.message === 'success') {
          if (pRes.contents.shoppackages.length === 20) {
            this.morePackage = true
          } else {
            this.morePackage = false
          }
        }
        const tRes = await store.dispatch(
          'getResourceShoppingTaskList',
          {
            keyword
          }
        )
        if (tRes.message === 'success') {
          if (tRes.contents.shoptasks.length === 20) {
            this.moreTask = true
          } else {
            this.moreTask = false
          }
        }
      }
      try {

      } catch (e) {

      }
      this.loading = false
    },
    async changeSearchType (type) {
      if (type === this.searchType) {
        return
      }
      this.searchType = type
      await this.doSearch()
    },
    async loadMorePackage () {
      if (this.loading) {
        return
      }
      this.loading = true
      const {
        keyword,
        shoppingPackageList
      } = this
      const res = await store.dispatch(
        'getResourceShoppingPackageList',
        {
          keyword,
          start: shoppingPackageList.length,
        }
      )
      if (res.message === 'success') {
        if (res.contents.shoppackages.length === 20) {
          this.morePackage = true
        } else {
          this.morePackage = false
        }
      }
      this.loading = false
    },
    async loadMoreTask () {
      if (this.loading) {
        return
      }
      this.loading = true
      const {
        keyword,
        shoppingTaskList
      } = this
      const res = await store.dispatch(
        'getResourceShoppingTaskList',
        {
          keyword,
          start: shoppingTaskList.length,
        }
      )
      if (res.message === 'success') {
        if (res.contents.shoptasks.length === 20) {
          this.moreTask = true
        } else {
          this.moreTask = false
        }
      }
      this.loading = false
    },
    checkTaskDetail (item) {
      this.$set(
        this,
        'currentTaskDetail',
        item
      )
    },
    goToSearch () {
      this.$set(
        this,
        'currentTaskDetail',
        undefined
      )
    },
    async changeCurrentPage (page) {
      await this.doSearch({
        pn: page
      })
    },
    changeSearchMode () {
      this.$emit('changeSearchMode', false)
    },
    clickCoursePackage (item) {
      console.log(item)
      // this.$set(
      //   this,
      //   'currentCoursePackage',
      //   item
      // )
      if (item) {
        this.getCoursePackage(item.id)
      } else {
        store.commit('setCoursePackageData', undefined)
      }
    },
    async getCoursePackage (id) {
      if (this.loading) {
        return
      }
      this.loading = true

      const res = await request.request(
        '/resource/queryCource',
        {
          courceId: id
        }
      )
      this.loading = false
      if (res.message === 'success') {
        store.commit('setCoursePackageData', new CoursePackage(res.contents.PCource))

        // this.coursePackage.updateData(res.contents.PCource)
        // let coursePackage = new CoursePackage(res.contents.PCource)
        // store.commit('setCoursePackageData', coursePackage)
      }
    },
    viewFile (data) {
      store.commit(
        'setResourceFileData',
        data
      )
    },
    backToSearch () {
      store.commit('setResourceFileData', undefined)
      store.commit('setCoursePackageData', undefined)
      this.$set(
        this,
        'sellPackage',
        undefined
      )
    },
    viewSearchSellPackage (item) {
      this.$set(
        this,
        'sellPackage',
        item
      )
    },
  }
}
</script>

<template>
<div
  v-loading="loading"
  class="v_fun_template_resource_search_index bbox_wrapper d-flex align-start flex-column"
>
  <div
    class="title_group d-flex align-center flex-shrink-0"
  >
    <div
      class="icon"
      @click="changeSearchMode"
    ></div>
    <div
      class="title"
    >
      搜索
    </div>
  </div>
  <div
    v-show="!currentTaskDetail"
    class="search_page_wrapper d-flex align-start flex-column"
  >
    <div
      v-show="!showDetail"
      class="search_wrapper d-flex align-center flex-shrink-0"
    >
      <div
        class="search_group d-flex align-center"
      >
        <div
          class="icon flex-shrink-0"
        ></div>
        <input
          v-model="keyword"
          type="text"
          class="input"
          placeholder="请输入"
          @keydown="inputKeyDown"
        >
      </div>
      <div
        class="btn cp"
      >
        搜索
      </div>
    </div>
    <div
      v-show="showDetail"
      class="navigation_group d-flex align-center flex-shrink-0"
    >
      <div
        class="tag cp"
        @click="backToSearch"
      >
        搜索
      </div>
      <div
        class="text"
      >
        >
      </div>
      <div
        class="tag on"
      >
        {{ detailName }}
      </div>
    </div>
    <div
      v-show="!showDetail"
      class="filter_wrapper flex-shrink-0"
    >
      <div
        class="filter_group d-flex align-center"
      >
        <div
          class="key"
        >
          分&nbsp;类:
        </div>
        <div
          class="value"
          :class="{
            on: searchType=== 1
          }"
          @click="changeSearchType(1)"
        >
          课程
        </div>
        <div
          class="value"
          :class="{
            on: searchType=== 2
          }"
          @click="changeSearchType(2)"
        >
          素材
        </div>
        <div
          class="value"
          :class="{
            on: searchType=== 3
          }"
          @click="changeSearchType(3)"
        >
          模板商城
        </div>
      </div>
    </div>
    <div
      v-show="!showDetail"
      class="content_wrapper "
    >
      <div
        v-if="searchType === 1"
        class="course_package_list_wrapper"
      >
        <div
          v-if="coursePackageList"
          class="course_package_list d-flex flex-wrap align-start"
        >
          <template
              v-for="(item, index) in coursePackageList"
            >
              <div
                :key="index"
                class="course_wrapper cp"
                @click="clickCoursePackage(item)"
              >
                <div
                  class="img_group"
                >
                  <div
                    class="img"
                    :style="{
                      backgroundImage: `url(${ $file_url }${ item.webpic })`
                    }"
                  ></div>
                </div>
                <div
                  class="info_group"
                >
                  <div
                    class="name"
                  >
                    {{ item.title }}
                  </div>
                  <div
                    class="info"
                  >
                    {{ item.ownedEnterprises }}｜{{ item.userName }}
                  </div>
                </div>
                <div
                  class="fun_group d-flex align-center justify-end"
                >
                  <div
                    class="icon user"
                  ></div>
                  <div
                    class="num"
                  >
                    {{ item.studyCount }}
                  </div>
                  <div
                    class="icon zan"
                  ></div>
                  <div
                    class="num"
                  >3392</div>
                </div>
              </div>
            </template>
        </div>

      </div>
      <div
        v-if="searchType === 2"
        class="file_list_wrapper"
      >
        <div
          v-if="resourceFileList"
          class="file_list d-flex flex-wrap align-start"
        >
          <template
            v-for="(item, index) in resourceFileList"
          >
            <div
              :key="index"
              class="resource_group d-flex align-center"
              @click="viewFile(item)"
            >
              <div
                class="icon_group flex-shrink-0"
              >
                <div
                  class="icon"
                  :class="{
                  [item.fileType]: true
                }"
                ></div>
              </div>
              <div
                class="info_group"
              >
                <div
                  class="name"
                >
                  {{ item.fileName }}
                </div>
                <div
                  class="detail_group d-flex align-center justify-space-between"
                >
                  <div
                    class="detail"
                  >
                    {{ item.userName }} | {{ item.ownedEnterprises }}
                  </div>
                  <div
                    class="number_group d-flex align-center"
                  >
                    <div
                      class="icon"
                    ></div>
                    <div
                      class="number"
                    >2.3w</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div
        v-if="searchType === 3 && !showDetail"
        class="shopping_wrapper"
      >
        <div
          class="shopping_group"
        >
          <div
            class="type_group"
          >
            <div
              class="type"
            >
              付费课程
            </div>
          </div>
          <div
            class="list_wrapper"
          >
            <div
              class="list"
            >
              <div
                class="package_wrapper d-flex align-start flex-wrap"
              >
                <template
                  v-for="(item, index) in shoppingPackageList"
                >
                  <div
                    :key="index"
                    class="package_group"
                    @click="viewSearchSellPackage(item)"
                  >
                    <div
                      class="img_group"
                    >
                      <div
                        class="img"
                        :style="{
                          backgroundImage: `url(${ $img_url }${ item.webpic })`
                        }"
                      ></div>
                    </div>
                    <div
                      class="name"
                    >
                      {{ item.title }}
                    </div>
                  </div>
                </template>
              </div>
              <div
                v-if="morePackage"
                class="more_group d-flex align-center justify-center"
              >
                <div
                  class="more d-flex cp align-center cp un_sel justify-center"
                  @click="loadMorePackage"
                >
                  <div
                    class="text"
                  >
                    查看更多
                  </div>
                  <div
                    class="icon"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="type_group"
          >
            <div
              class="type"
            >
              任务模板
            </div>
          </div>
          <div
            class="list_wrapper"
          >
            <div
              class="list"
            >
              <div
                class="task_wrapper d-flex align-start flex-wrap"
              >
                <template
                  v-for="(item, index) in shoppingTaskList"
                >
                  <TaskModule
                    class="task_group"
                    :key="index"
                    :ptask="item"
                    @click.native="checkTaskDetail(item)"
                  ></TaskModule>
                </template>
              </div>
            </div>
            <div
              v-if="moreTask"
              class="more_group d-flex align-center justify-center"
            >
              <div
                class="more d-flex cp align-center cp un_sel justify-center"
                @click="loadMoreTask"
              >
                <div
                  class="text"
                >
                  查看更多
                </div>
                <div
                  class="icon"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="showDetail"
      class="content_wrapper"
    >
      <CheckCrousePackage
        v-if="coursePackage"
        :show-top="false"
        :view-mode="true"
        :audit-mode="false"
      ></CheckCrousePackage>
      <ResourceCenterDetail
        v-if="resourceFileData"
      ></ResourceCenterDetail>
      <div
        v-if="sellPackage"
        class="task_detail_wrapper"
      >
        <TeachingPackageDetailPage
          :package_id="sellPackage.id"
          :pop-task="true"
        ></TeachingPackageDetailPage>
      </div>
    </div>
    <div
      v-if="searchType !== 3 && !showDetail"
      class="page_wrapper flex-shrink-0 d-flex justify-center"
    >
      <!--{{ page }}-->
      <el-pagination
        v-if="page"
        background
        layout="prev, pager, next"
        :total="page.totalPages"
        :current-page="page.pageNo"
        @current-change="changeCurrentPage"
      >
      </el-pagination>
    </div>

  </div>
  <div
    v-show="currentTaskDetail"
    class="detail_wrapper d-flex align-start flex-column"
  >
    <div
      v-if="currentTaskDetail"
      class="return_wrapper d-felx align-center flex-shrink-0"
    >
      <div
        class="text cp"
        @click="goToSearch"
      >
        搜索
      </div>
      <div
        class="symbol"
      >
        &gt;
      </div>
      <div
        class="text on"
      >
        {{ currentTaskDetail.name }}
      </div>
    </div>
    <div
      v-if="currentTaskDetail"
      class="task_detail_wrapper"
    >
      <TaskDetail
        :task_id="currentTaskDetail.id"
      ></TaskDetail>
    </div>

  </div>


</div>
</template>

<style scoped lang="scss">
@mixin type($type) {
  &.#{$type} {
    background-image: url(~@/assets/images/resource/#{$type}.png);
  }
}
.v_fun_template_resource_search_index {
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: auto;
  .search_page_wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
    .navigation_group {
      width: 100%;
      padding: 20px;
      font-size: 14px;
      color: #666666;
      line-height: 19px;
      text-align: left;
      font-style: normal;
      .tag {
        @include transition;
        &.on {
          color: $main_blue;
        }
      }
      .text {

      }
    }
  }
  .detail_wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
    .return_wrapper {
      width: 100%;
      padding: 10px 40px;
      font-size: 14px;
      color: #999;
      .text {
        &.on {
          color: $main_blue;
        }
      }
      .symbol {
        padding: 0 3px;
      }
    }
    .task_detail_wrapper {
      width: 100%;
      height: 100%;
      overflow: auto;
      padding: 0 40px;
    }
  }
  .title_group {
    width: 100%;
    padding: 20px 40px;
    border-bottom: 1px solid #f4f4f4;
    .icon {
      width: 14px;
      height: 20px;
      background-image: url(~@/assets/images/resource/return.png);
    }
    .title {
      padding-left: 16px;
      font-weight: bold;
      font-size: 24px;
      color: #333333;
      line-height: 31px;
      text-align: left;
      font-style: normal;
    }
  }
  .search_wrapper {
    width: 100%;
    overflow: auto;
    padding: {
      top: 20px;
      left: 40px;
      right: 40px;
    }
    .search_group {
      width: 300px;
      height: 40px;
      background-color: #F0F0F0;
      border-radius: 4px;
      overflow: auto;
      margin-right: 10px;
      padding: 0 10px;
      .icon {
        width: 16px;
        height: 16px;
        background-image: url(~@/assets/images/resource/search.png);

      }
      .input {
        @include placeholder(#999);
        width: 100%;
        font-size: 14px;
        color: #333;
        line-height: 19px;
        text-align: left;
        font-style: normal;
        padding: 0 10px;
        outline: none;
        border: none;
      }
    }
    .btn {
      width: 70px;
      background: #0054A7;
      border-radius: 4px;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 40px;
      text-align: center;
      font-style: normal;
    }
  }
  .filter_wrapper {
    width: 100%;
    padding: {
      top: 20px;
      left: 40px;
      right: 40px;
    }
    .filter_group {
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
    }
    .key {
      width: 71px;
      font-size: 14px;
      color: #333333;
      line-height: 45px;
      text-align: justify;
      text-align-last: justify;
      font-style: normal;
      background-color: #F6F6F6;
      padding: {
        left: 17px;
        right: 12px;
      }
    }
    .value {
      cursor: pointer;
      font-size: 14px;
      color: #333333;
      line-height: 45px;
      text-align: left;
      font-style: normal;
      margin-left: 30px;
      &.on {
        color: $main_blue;
      }
    }
  }
  .content_wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
    .course_package_list_wrapper {
      width: 100%;
      height: 100%;
      overflow: auto;
      .course_package_list {
        width: 100%;
        height: 100%;
        padding: {
          left: 40px;
          right: 40px;
        }
        overflow: auto;
        .course_package_detail_wrapper {
          padding-top: 20px;
        }

        .course_wrapper {
            @include transition;
            width: 288px;
            height: 280px;
            background: #FFFFFF;
            box-shadow: 0 0 10px 0 rgba(0,0,0,0.06);
            border-radius: 16px;
            border: 1px solid #E8EEFF;
            margin: {
              right: 16px;
              top: 16px;
            }
            &:hover {
              box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
            }
            .img_group {
              width: 288px;
              height: 162px;
              border-radius: 16px 16px 0 0;
              overflow: hidden;
              .img {
                @include background;
                width: 100%;
                height: 100%;
                background-size: cover;
              }
            }
            .info_group {
              width: 100%;
              height: 74px;
              padding: 0 16px;
              border-bottom: 2px solid #F8F8F8;
              .name {
                @include single_line_intercept;
                padding-top: 16px;
                padding-bottom: 4px;
                width: 100%;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
                line-height: 22px;
                text-align: justify;
                font-style: normal;
                text-transform: uppercase;
              }
              .info {
                @include single_line_intercept;
                width: 100%;
                font-weight: 400;
                font-size: 14px;
                color: #999999;
                text-align: left;
                font-style: normal;
                text-transform: uppercase;
              }
            }
            .fun_group {
              height: 40px;
              width: 100%;
              padding: 0 16px;
              .icon {
                width: 14px;
                height: 14px;
                margin-right: 5px;
                margin-left: 16px;
                &.user {
                  background-image: url(~@/assets/images/resource/course_user.png);
                }
                &.zan {
                  background-image: url(~@/assets/images/resource/course_zan.png);
                }
              }
              .num {
                font-weight: 400;
                font-size: 12px;
                color: #AAAAAA;
                line-height: 17px;
                text-align: right;
                font-style: normal;
              }
            }
          }
      }
    }
    .file_list_wrapper {
      width: 100%;
      height: 100%;
      overflow: auto;
      .file_list {
        width: 100%;
        height: 100%;
        padding: {
          left: 40px;
          right: 40px;
        }
        overflow: auto;
      }
      .resource_group {
        width: 330px;
        height: 64px;
        padding: 0 16px;
        background: #FFFFFF;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.06);
        border-radius: 10px;
        border: 1px solid #E8EEFF;
        margin: {
          top: 10px;
          right: 16px;
          bottom: 16px;
        }
        .icon_group {
          margin-right: 10px;
          .icon {
            @include background;
            @include type(zip);
            @include type(excel);
            @include type(video);
            @include type(pdf);
            @include type(pic);
            @include type(ppt);
            @include type(txt);
            @include type(unknown);
            @include type(word);
            @include type(vr);
            @include type(audio);
            @include type(task);
            @include type(link);
            width: 32px;
            height: 32px;
          }
        }
        .info_group {
          overflow: auto;
          .name {
            @include single_line_intercept;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            line-height: 20px;
            text-align: left;
            font-style: normal;
          }
          .detail_group {
            padding-top: 5px;
            .detail {
              @include single_line_intercept;
              font-weight: 400;
              font-size: 12px;
              color: #9F9F9F;
              text-align: left;
              font-style: normal;
            }
            .number_group {
              padding-left: 10px;
              .icon {
                background-image: url(~@/assets/images/resource/center_of_resource_review.png);
                width: 20px;
                height: 14px;
              }
              .number {
                padding-left: 3px;
                font-size: 12px;
                color: #666666;
                text-align: left;
                font-style: normal;
              }
            }
          }
        }
      }
    }
    .shopping_wrapper {
      width: 100%;
      height: 100%;
      overflow: auto;
      padding: 20px 0;
      .shopping_group {
        width: 100%;
        height: 100%;
        overflow: auto;
        .type_group {
          width: 100%;
          padding-bottom: 20px;
          .type {
            font-size: 16px;
            color: #0054A7;
            line-height: 21px;
            text-align: left;
            font-style: normal;
            padding-left: 40px;
            border-left: 4px solid $main_blue;
          }
        }
        .list_wrapper {
          width: 100%;
          padding: 0 40px;
          .list {
            width: 100%;
            .package_wrapper {
              width: 100%;
              .package_group {
                width: 288px;
                margin: {
                  right: 20px;
                  bottom: 20px;
                }
                .img_group {
                  width: 288px;
                  height: 162px;
                  .img {
                    background-size: contain;
                    width: 100%;
                    height: 100%;
                    border-radius: 3px;
                  }
                }
                .name {
                  padding-top: 10px;
                  width: 100%;
                  font-size: 14px;
                  color: #333333;
                  line-height: 20px;
                  text-align: left;
                  font-style: normal;
                  text-transform: uppercase;
                }
              }
            }
            .task_wrapper {
              width: 100%;
              .task_group {
                margin: {
                  right: 16px;
                  bottom: 16px;
                }
              }
            }
          }
          .more_group {
            width: 100%;
            padding: 5px 0;
            .more {
              width: 100px;
              height: 32px;
              background: #F4F4F4;
              border-radius: 4px;
              .text {
                font-size: 14px;
                color: #333333;
                line-height: 19px;
                text-align: left;
                font-style: normal;
              }
              .icon {
                width: 13px;
                height: 8px;
                background-image: url(~@/assets/images/resource/shopping_more.png);
              }
            }
          }
        }
      }
    }
  }
  .page_wrapper {
    width: 100%;
    padding: 5px 0;
  }
}
</style>
