var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_fun_template_resource_title_group d-flex align-center justify-space-between un_sel bbox_wrapper",
    },
    [
      _c("div", { staticClass: "name" }, [
        _vm._v("\n    会展与商务活动专业群实践教学资源库\n  "),
      ]),
      _c("div", { staticClass: "fun_group d-flex align-center" }, [
        _c("div", {
          staticClass: "search icon cp",
          on: { click: _vm.goToSearch },
        }),
        _c("div", { staticClass: "user icon" }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }