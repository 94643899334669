var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "v_resource_resource_center_index",
    },
    [
      !_vm.resourceFileData
        ? _c(
            "div",
            { staticClass: "filter_group d-flex align-center flex-shrink-0" },
            [
              _vm._m(0),
              _vm._m(1),
              _c("div", { staticClass: "course_number" }, [
                _vm._v("\n      共计1000门课程\n    "),
              ]),
            ]
          )
        : _vm._e(),
      _vm.resourceFileData
        ? _c(
            "div",
            {
              staticClass: "navigation_group d-flex align-center flex-shrink-0",
            },
            [
              _c(
                "div",
                {
                  staticClass: "tag cp",
                  on: {
                    click: function ($event) {
                      return _vm.viewFile(undefined)
                    },
                  },
                },
                [_vm._v("\n      资源中心\n    ")]
              ),
              _c("div", { staticClass: "text" }, [_vm._v("\n      >\n    ")]),
              _c("div", { staticClass: "tag on" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.resourceFileData && _vm.resourceFileData.fileName
                    ) +
                    "\n    "
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "content_wrapper" },
        [
          _vm.resourceFileData ? _c("ResourceCenterDetail") : _vm._e(),
          !_vm.resourceFileData
            ? _c("div", { staticClass: "resource_list_wrapper" }, [
                _c(
                  "div",
                  { staticClass: "list_group d-flex flex-wrap align-start" },
                  [
                    _vm._l(_vm.resourceFileList, function (item, index) {
                      return [
                        _c(
                          "div",
                          {
                            key: index,
                            staticClass: "resource_group d-flex align-center",
                            on: {
                              click: function ($event) {
                                return _vm.viewFile(item)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "icon_group flex-shrink-0" },
                              [
                                _c("div", {
                                  staticClass: "icon",
                                  class: {
                                    [item.fileType]: true,
                                  },
                                }),
                              ]
                            ),
                            _c("div", { staticClass: "info_group" }, [
                              _c("div", { staticClass: "name" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.fileName) +
                                    "\n              "
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "detail_group d-flex align-center justify-space-between",
                                },
                                [
                                  _c("div", { staticClass: "detail" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item.userName) +
                                        " | " +
                                        _vm._s(item.ownedEnterprises) +
                                        "\n                "
                                    ),
                                  ]),
                                  _vm._m(2, true),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tag_group d-flex align-center on" }, [
      _c("div", { staticClass: "tag" }, [_vm._v("\n        最新\n      ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tag_group d-flex align-center" }, [
      _c("div", { staticClass: "icon hot" }),
      _c("div", { staticClass: "tag" }, [_vm._v("\n        热门\n      ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "number_group d-flex align-center" }, [
      _c("div", { staticClass: "icon" }),
      _c("div", { staticClass: "number" }, [_vm._v("2.3w")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }